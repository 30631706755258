let state = {
    posts: [],
    post: {
        id: '',
        title: '',
        content: ''
    },
    anagrafiche: [],
    anagrafica: {
        id: '',
        cognome: '',
        nome: '',
        targa: '',
        email: '',
        telefono: '',
    }
}

export default state
