let mutations = {
    CREATE_POST(state, post) {
        state.posts.unshift(post)
    },
    FETCH_POSTS(state, posts) {
        return state.posts = posts
    },
    DELETE_POST(state, post) {
        let index = state.posts.findIndex(item => item.id === post.id)
        state.posts.splice(index, 1)
    },
    UPDATE_POST(state, post) {
        let index = state.posts.findIndex(item => item.id == post.id)
        var a = post.indexOf(post.id);
        console.log(post)
        state.posts.unshift(post)
    },

    setId : (state,id) => {
        state.post.id = id;
      },    
    setTitle : (state,title) => {
        state.post.title = title;
      },
    setContent : (state,content) => {
        state.post.content = content;
    }

}
export default mutations