<template>
    <v-app id="inspire">

        <v-card>
            <v-card-title>
            <span class="headline">Filtri</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="filtro_cognome" label="Cognome"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="filtro_nome" label="Nome"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="filtro_targa" label="Targa"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-layout justify-center>
                <v-card-actions >
                    <v-spacer></v-spacer>
                    <v-btn  class="ma-2" outlined color="indigo" dark @click="filtra">Filtra</v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>




         <v-card>
            <v-card-title>
                Gestione Anagrafica - Vuejs
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cerca"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="anagrafiche"  
                :search="search"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                class="elevation-1"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100], // -> Add this example
                    showFirstLastPage: true,
                    //firstIcon: 'mdi-arrow-collapse-left',
                    //lastIcon: 'mdi-arrow-collapse-right',
                    //prevIcon: 'mdi-minus',
                    //nextIcon: 'mdi-plus'
                }"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!--
                        <v-toolbar-title>Tabella Anagrafica</v-toolbar-title>
                        <v-spacer></v-spacer>
                        -->
                    </v-toolbar>

                        <v-dialog v-model="dialog" max-width="900px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                class="ma-2" outlined color="indigo"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >Nuova Anagrafica</v-btn>
                            </template>
                            <v-spacer></v-spacer>
                            <v-card>
                                <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="anagrafica.cognome" label="Cognome"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="anagrafica.nome" label="Nome"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="anagrafica.targa" label="Targa"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="anagrafica.email" label="Email"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="anagrafica.telefono" label="Telefono"></v-text-field>
                                            </v-col>
                                            <v-col class="d-flex" cols="12" sm="6"  md="4">
                                                <v-select
                                                :items="utenti"
                                                item-text='name'
                                                item-value='id'
                                                label="Operatori"
                                                v-model="anagrafica.id_operatore"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="save">Salva</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!--
                        <v-dialog
                            v-model="dialog"
                            max-width="290"
                        >
                            <v-card>
                            <v-card-title class="headline">Use Google's location service?</v-card-title>
                    
                            <v-card-text>
                                Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
                            </v-card-text>
                    
                            <v-card-actions>
                                <v-spacer></v-spacer>
                    
                                <v-btn
                                color="green darken-1"
                                text
                                @click="dialog = false"
                                >
                                Disagree
                                </v-btn>
                    
                                <v-btn
                                color="green darken-1"
                                text
                                @click="dialog = false"
                                >
                                Agree
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        -->

                        <v-overlay :value="overlay">
                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>

                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">Ulteriori informazioni riguardo {{ item.cognome }}</td>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
         </v-card>
    </v-app>
</template>

<script>
    import axios from "axios";
    export default {
        name: "Anagrafica",
        data() {
            return{
                dialog: false,
                overlay: false,
                expanded: [],
                singleExpand: true,
                search: '',
                headers: [
                {
                    text: 'ID-Record',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                    { text: 'Cognome', value: 'cognome' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Targa', value: 'targa' },
                    { text: 'Email', value: 'email' },
                    { text: 'Telefono', value: 'telefono' },
                    { text: 'Operatore', value: 'operatore' },
                    { text: 'espandi', value: 'data-table-expand' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                anagrafiche: [],
                anagrafica: {
                    id: '',
                    cognome: '',
                    nome: '',
                    targa: '',
                    email: '',
                    telefono: '',
                    id_operatore: '',

                },
                editedIndex: -1,
                defaultItem: {
                    id: '',
                    cognome: '',
                    nome: '',
                    targa: '',
                    email: '',
                    telefono: '',
                    id_operatore: '',
                },
                filtro_cognome: '',
                filtro_nome: '',
                filtro_targa: '',
                utenti: [],
                utente: {
                    id: '',
                    name: '',
                    email: '',
                },

            }
        },
        computed: {
                formTitle () {
                    return this.editedIndex === -1 ? 'Nuova Anagrafica' : 'Modifica Anagrafica'
                }
            },
        mounted() {
            console.log('componente montato');
            this.getData();
            this.getUtenti();
        },
        methods: {
            getData(){
                this.overlay = true
                return axios
                    .post("api/anagrafiche")
                    .then(response => {
                    console.log(response);
                    this.anagrafiche = response.data
                    this.overlay = false
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },
            getUtenti(){
                return axios
                    .post("api/utenti")
                    .then(response => {
                    console.log("Utenti ", response.data);
                    this.utenti = response.data
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },
            filtra(){
                    const config = {
                        headers: {
                        "Content-Type": "application/json"
                        }
                    };
                    var data = JSON.stringify(
                        (data = {
                            cognome: this.filtro_cognome,
                            nome: this.filtro_nome,
                            targa: this.filtro_targa,
                        })
                    );

                this.overlay = true
                return axios
                    .post("api/anagrafiche", data, config)
                    .then(response => {
                    console.log(response);
                    this.anagrafiche = response.data
                    this.overlay = false
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },

            deleteAnagrafica(anagrafica) {
                return axios
                .delete(`/api/anagrafiche/${anagrafica.id}`)
                .then(response => {
                    console.log(response);
                    if (response.data === 'ok'){
                        let index = this.anagrafiche.findIndex(item => item.id === anagrafica.id)
                        this.anagrafiche.splice(index, 1)
                    }
                }).catch(err => {
                console.log(err)
                })
            },
            editItem (item) {
                    this.editedIndex = this.anagrafiche.indexOf(item)
                    this.anagrafica = Object.assign({}, item)
                    this.dialog = true
                },
            close () {
                    this.dialog = false
                    this.$nextTick(() => {
                    this.anagrafica = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    })
                },
            save () {
                    const config = {
                        headers: {
                        "Content-Type": "application/json"
                        }
                    };
                    var data = JSON.stringify(
                        (data = {
                            id: this.anagrafica.id,
                            cognome: this.anagrafica.cognome,
                            nome: this.anagrafica.nome,
                            targa: this.anagrafica.targa,
                            email: this.anagrafica.email,
                            telefono: this.anagrafica.telefono,
                            operatore: this.anagrafica.id_operatore,
                        })
                    );
                    if (this.editedIndex > -1) {
                        return axios
                            .post("api/edit_anagrafica", data, config)
                            .then(response => {
                                //console.log(response);
                                 if (response.data === 'ok'){
                                    Object.assign(this.anagrafiche[this.editedIndex], this.anagrafica)
                                    console.log ("AAAAAAA", this.dialog )
                                    this.dialog = false
                                }
                            })
                            .catch(e => {
                            console.log(e);
                            });
                    } else {
                        return axios
                            .post("api/save_anagrafica",data,config)
                            .then(response => {
                                 if (response.data === 'ok'){
                                    this.anagrafiche.push(this.anagrafica)
                                    this.dialog = false
                                }
                            })
                            .catch(e => {
                            console.log(e);
                            });
                    }
                    
                    this.close()
                }



        }

    }



</script>

<style scoped>

</style>