var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "text-center font-weight-bold" }, [
        _vm._v("Posts")
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table-striped" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.posts, function(post) {
            return _c("tr", { key: post.id }, [
              _c("td", [_vm._v(_vm._s(post.title))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(post.content))]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.deletePost(post)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-trash",
                      staticStyle: { color: "white" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.editPost(post)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-pen",
                      staticStyle: { color: "white" },
                      attrs: {
                        "data-toggle": "modal",
                        "data-target": "#update-item"
                      }
                    })
                  ]
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                "\n            Tabella Vuetify in visualizzazione\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Cerca",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.posts,
              search: _vm.search,
              "single-expand": _vm.singleExpand,
              expanded: _vm.expanded,
              "item-key": "id",
              "show-expand": ""
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Expandable Table")]),
                        _vm._v(" "),
                        _c("v-spacer")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "290" },
                        model: {
                          value: _vm.dialog,
                          callback: function($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "headline" }, [
                              _vm._v("Use Google's location service?")
                            ]),
                            _vm._v(" "),
                            _c("v-card-text", [
                              _vm._v(
                                "\n                            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "green darken-1",
                                      text: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.dialog = false
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Disagree\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "green darken-1",
                                      text: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.dialog = false
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Agree\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c("td", { attrs: { colspan: headers.length } }, [
                      _vm._v(
                        "Uteriori informazioni riguardo " + _vm._s(item.title)
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.editItem(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    mdi-pencil\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.deleteItem(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    mdi-delete\n                "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "v-app",
        [
          _c("v-alert", { attrs: { value: true, type: "success" } }, [
            _vm._v("\n            Vuetify installato correttamente.\n        ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Content")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }