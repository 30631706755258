<template>
    <div>


        <h4 class="text-center font-weight-bold">Posts</h4>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">Title</th>
                <th scope="col">Content</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
             <tr v-for="post in posts" :key=post.id>
                <td>{{post.title}}</td>
                <td>{{post.content}}</td>
                <td>
                    <button class="btn btn-danger" @click="deletePost(post)"><i style="color:white" class="fa fa-trash"></i></button>
                    <button class="btn btn-primary" @click="editPost(post)"><i style="color:white" class="fas fa-pen" data-toggle="modal" data-target="#update-item"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        <hr>

         <v-card>
            <v-card-title>
                Tabella Vuetify in visualizzazione
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cerca"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <!--
              <v-data-table
                :headers="headers"
                :items="posts"
                :sort-by="['title', 'content']"
                :sort-desc="[false, true]"
                multi-sort
                class="elevation-1"
            ></v-data-table>
            -->
            <v-data-table
                :headers="headers"
                :items="posts"
                :search="search"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Expandable Table</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                        <v-dialog
                            v-model="dialog"
                            max-width="290"
                            >
                            <v-card>
                                <v-card-title class="headline">Use Google's location service?</v-card-title>

                                <v-card-text>
                                Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                    color="green darken-1"
                                    text
                                    @click="dialog = false"
                                >
                                    Disagree
                                </v-btn>

                                <v-btn
                                    color="green darken-1"
                                    text
                                    @click="dialog = false"
                                >
                                    Agree
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>


                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">Uteriori informazioni riguardo {{ item.title }}</td>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <hr>
        <v-app>
            <v-alert
                :value="true"
                type="success"
            >
                Vuetify installato correttamente.
            </v-alert>
        </v-app>
    </div>

</template>

<script>
    import axios from "axios";
    export default {
        name: "Posts1",
        data() {
            return{
                dialog: false,
                expanded: [],
                singleExpand: true,
                search: '',
                headers: [
                {
                    text: 'ID-Record',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                    { text: 'Titolo', value: 'title' },
                    { text: 'Contenuto', value: 'content' },
                    { text: 'espandi', value: 'data-table-expand' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                posts: [],
                post: {
                    id: '',
                    title: '',
                    content: ''
                }
            }
        },
        mounted() {
            console.log('componente montato');
            this.getData()
        },
        methods: {
            getData(){
                return axios
                    .get("api/posts")
                    .then(response => {
                    console.log(response);
                    this.posts = response.data
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },
            deletePost(post) {
                return axios
                .delete(`/api/posts/${post.id}`)
                .then(response => {
                    console.log(response);
                    if (response.data === 'ok'){
                        let index = this.posts.findIndex(item => item.id === post.id)
                        this.posts.splice(index, 1)
                    }
                }).catch(err => {
                console.log(err)
                })
            },
            editPost(post){
                //Devo usare le variabili definite nello state dello store per passarle ad un altro componente.
                this.post.id = post.id;
                this.$store.state.post.id = post.id;
                this.post.title = post.title;
                this.$store.state.post.title = post.title;
                this.post.content = post.content;
                this.$store.state.post.content = post.content;
            },
            editItem (item) {
            //        this.editedIndex = this.desserts.indexOf(item)
            //        this.editedItem = Object.assign({}, item)
                    this.dialog = true
                },

        }

    }


 



</script>

<style scoped>

</style>