/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

import store from './store/index'
import vuetify from "./plugins/vuetify";

Vue.component('mio-componente', require('./components/MioPrimoComponente.vue').default);
Vue.component('secondo-componente', require('./components/SecondoComponente.vue').default);
Vue.component('appvue01-component', require('./components/ExampleComponent.vue').default);

Vue.component('posts', require('./components/Post/Posts.vue').default);
Vue.component('posts1', require('./components/Post/Posts1.vue').default);
Vue.component('create-post', require('./components/Post/CreatePost.vue').default);
Vue.component('update-post', require('./components/Post/UpdatePost.vue').default);
Vue.component('anagrafica', require('./components/Anagrafica.vue').default);

Vue.component('novdt', require('./components/NoVdt.vue').default);
Vue.component('non_attivi', require('./components/NonAttivi.vue').default);
Vue.component('pausa_caffe', require('./components/PausaCaffe.vue').default);
Vue.component('pausa_pranzo', require('./components/PausaPranzo.vue').default);

Vue.component('pause_ok', require('./components/PauseOK.vue').default);
Vue.component('pause_nook', require('./components/PauseNoOk.vue').default);

Vue.component('bloccati', require('./components/UtentiBloccati.vue').default);
Vue.component('loggati', require('./components/UtentiLoggati.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

export const eventBus = new Vue({
  methods : {

    inviaStringa(colore){
      this.$emit('inviaStringa',colore);
    }
    
  }
});

//multiple instance of Vue https://www.youtube.com/watch?v=k5p3TozQt84
const app = new Vue({
    el: '#app',
    store,
    vuetify,
    data() {
      return{
          AAA : 'Valore_AAA definito in app.js',
          //  Vue ----
          heading : 'Ciclo  V-for',
          newTask : 'foobar',
          
          todos: [
            { text: 'Learn JavaScript' },
            { text: 'Learn Vue' },
            { text: 'Build something awesome' }
            ],

          tasks : [
            { body: 'Go to the store', completed: false }
          ]

      }
    },
    methods: {
      addTask(e) {
        //The preventDefault() method stops the default action  (submit Form) of a selected 
        //element from happening by a user (sostituisco alla submit l'aggiunta del record)
        //e non ricarica la pagina dal submit
        e.preventDefault();

        //aggiungo un elemento all'array al volo:
        this.tasks.push(
          { body: this.newTask, completed: false }
        )
        //alert (this.newTask)
      },
      removeTask(task){
        alert (task);
        //this.tasks.$remove(task);
        //console.log (this.tasks.task.findIndex())

      }
    }
})

/*
const vue01 = new Vue({
  el: '#vue01',
  store,
  vuetify,
  data() {
    return{
        A : '5',
    }
  },
  methods: {
    
  }
})
*/