import { render, staticRenderFns } from "./UtentiBloccati.vue?vue&type=template&id=63203339&scoped=true&"
import script from "./UtentiBloccati.vue?vue&type=script&lang=js&"
export * from "./UtentiBloccati.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63203339",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Attivita\\SVILUPPO Laravel\\archilogy\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63203339')) {
      api.createRecord('63203339', component.options)
    } else {
      api.reload('63203339', component.options)
    }
    module.hot.accept("./UtentiBloccati.vue?vue&type=template&id=63203339&scoped=true&", function () {
      api.rerender('63203339', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/UtentiBloccati.vue"
export default component.exports