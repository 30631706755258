var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("Filtri")])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Cognome" },
                            model: {
                              value: _vm.filtro_cognome,
                              callback: function($$v) {
                                _vm.filtro_cognome = $$v
                              },
                              expression: "filtro_cognome"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Nome" },
                            model: {
                              value: _vm.filtro_nome,
                              callback: function($$v) {
                                _vm.filtro_nome = $$v
                              },
                              expression: "filtro_nome"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Targa" },
                            model: {
                              value: _vm.filtro_targa,
                              callback: function($$v) {
                                _vm.filtro_targa = $$v
                              },
                              expression: "filtro_targa"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { outlined: "", color: "indigo", dark: "" },
                      on: { click: _vm.filtra }
                    },
                    [_vm._v("Filtra")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v("\n            Gestione Anagrafica - Vuejs\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Cerca",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.anagrafiche,
              search: _vm.search,
              "single-expand": _vm.singleExpand,
              expanded: _vm.expanded,
              "item-key": "id",
              "show-expand": "",
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100], // -> Add this example
                showFirstLastPage: true
                //firstIcon: 'mdi-arrow-collapse-left',
                //lastIcon: 'mdi-arrow-collapse-right',
                //prevIcon: 'mdi-minus',
                //nextIcon: 'mdi-plus'
              }
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-toolbar", { attrs: { flat: "" } }),
                    _vm._v(" "),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "900px" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ma-2",
                                        attrs: {
                                          outlined: "",
                                          color: "indigo",
                                          dark: ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("Nuova Anagrafica")]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.dialog,
                          callback: function($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog"
                        }
                      },
                      [
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c("span", { staticClass: "headline" }, [
                                _vm._v(_vm._s(_vm.formTitle))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Cognome" },
                                              model: {
                                                value: _vm.anagrafica.cognome,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "cognome",
                                                    $$v
                                                  )
                                                },
                                                expression: "anagrafica.cognome"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Nome" },
                                              model: {
                                                value: _vm.anagrafica.nome,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "nome",
                                                    $$v
                                                  )
                                                },
                                                expression: "anagrafica.nome"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Targa" },
                                              model: {
                                                value: _vm.anagrafica.targa,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "targa",
                                                    $$v
                                                  )
                                                },
                                                expression: "anagrafica.targa"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Email" },
                                              model: {
                                                value: _vm.anagrafica.email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "anagrafica.email"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Telefono" },
                                              model: {
                                                value: _vm.anagrafica.telefono,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "telefono",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "anagrafica.telefono"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex",
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.utenti,
                                                "item-text": "name",
                                                "item-value": "id",
                                                label: "Operatori"
                                              },
                                              model: {
                                                value:
                                                  _vm.anagrafica.id_operatore,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.anagrafica,
                                                    "id_operatore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "anagrafica.id_operatore"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.close }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.save }
                                  },
                                  [_vm._v("Salva")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-overlay",
                      { attrs: { value: _vm.overlay } },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", size: "64" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c("td", { attrs: { colspan: headers.length } }, [
                      _vm._v(
                        "Ulteriori informazioni riguardo " +
                          _vm._s(item.cognome)
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.editItem(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    mdi-pencil\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.deleteItem(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    mdi-delete\n                "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }