import { render, staticRenderFns } from "./NonAttivi.vue?vue&type=template&id=7885c426&scoped=true&"
import script from "./NonAttivi.vue?vue&type=script&lang=js&"
export * from "./NonAttivi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7885c426",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Attivita\\SVILUPPO Laravel\\archilogy\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7885c426')) {
      api.createRecord('7885c426', component.options)
    } else {
      api.reload('7885c426', component.options)
    }
    module.hot.accept("./NonAttivi.vue?vue&type=template&id=7885c426&scoped=true&", function () {
      api.rerender('7885c426', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/NonAttivi.vue"
export default component.exports