let actions = {
    createPost({commit}, post) {
        axios.post('/api/posts', post)
            .then(res => {
                commit('CREATE_POST', res.data)
                window.close();
            }).catch(err => {
            console.log(err)
        })
    },
    updatePost({commit}, post) {
        console.log(post.id)
        axios.post(`/api/update-post/${post.id}`, post)
        .then((response) => {
            window.location.replace('/VuePosts');
        }).catch(err => {
            console.log(err)
        })
    },
    fetchPosts({commit}) {
        axios.get('/api/posts')
            .then(res => {
                commit('FETCH_POSTS', res.data)
            }).catch(err => {
            console.log(err)
        })
    },
    deletePost({commit}, post) {
        axios.delete(`/api/posts/${post.id}`)
            .then(res => {
                if (res.data === 'ok')
                    commit('DELETE_POST', post)
            }).catch(err => {
            console.log(err)
        })
    },
    editPost({commit}, post) {
        commit('setId', post.id)
        commit('setTitle', post.title)
        commit('setContent', post.content)
    }

}
export default actions