let getters = {
     posts: state => {
         return state.posts
     },
     getId: state =>{
        return state.post.id;
    },
     getTitle: state =>{
         return state.post.title;
     },
     getContent: state =>{
        return state.post.content;
    }

}
export default  getters