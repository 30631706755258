<template> 
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title" >Primo Componenete</h5>
                        <br>
                        <p>Variabile: {{ miavariabile }}</p> 
                        <button class="btn btn-outline-secondary" @click="cambiaingiallo()" >Cambia</button>
                        <hr>
                        <h6>Mia Var. Colore : {{ colore }}</h6>
                        <button @click="invia">Invia Stringa</button>
                        <h6>Invio con <b>eventBus</b> passo parametro: "Stringa inviata" </h6>
                    </div>
                </div>
            </div>
        </div>
</template>    



<script>
    import {eventbus, eventBus} from '../app';

    export default {
        name : 'MioPrimoComponente',
        data() {
            return{
                miavariabile: 'rosso'
            }
        },
        mounted(){
            console.log('componente montato');
            //this.miavariabile = 'Giallo';
        },
        methods:{
            //event è implicito come parametro anche se non viene passato (per le funzioni con parametri deve essere passato)
            cambiaingiallo(){
                console.log('GIALLO');
                this.miavariabile = 'Giallo';
            },
            invia(){
                eventBus.inviaStringa('Stringa inviata');
            }
        },
        props : {
            'colore' : {
                type : String,
                default : 'Verde'
            }
        }
    }
</script>

<style scoped>
    p {
        border: 1px solid black;
        font-size: 56px;
    }
</style>


