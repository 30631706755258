var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "text-center font-weight-bold" }, [
      _vm._v("Posts")
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table table-striped" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.posts, function(post) {
          return _c("tr", { key: post.id }, [
            _c("td", [_vm._v(_vm._s(post.title))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(post.content))]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: {
                    click: function($event) {
                      return _vm.deletePost(post)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-trash",
                    staticStyle: { color: "white" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.editPost(post)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pen",
                    staticStyle: { color: "white" },
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#update-item"
                    }
                  })
                ]
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Content")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }