<template>
    <form action="" @submit ="updatePost">
        <h4 class="text-center font-weight-bold">Update Post</h4>
        <br>
        Id = {{ getId }} , titolo {{title}}
        <div class="form-group">
            <input type="text" placeholder="Post title" v-model="title" class="form-control">
        </div>
        <div class="form-group">
            <textarea v-model="content" placeholder="Post content" class="form-control">
            </textarea>
        </div>
        <div class="form-group">
            <button :disabled="!isValid" class="btn btn-block btn-primary" @click.prevent="updatePost">Submit</button>
        </div>
    </form>
</template>

<script>

    import {mapGetters,mapMutations} from 'vuex'
    
    export default {
        name: "CreatePost",
        data() {
            return {
                page: {}
            }
        },
        mounted() {

        },
        methods: {
           
            updatePost() {
                    this.$store.dispatch('updatePost', this.$store.state.post)
                   
            },
            
        ...mapMutations([
                'setTitle'
                ])

        },
        computed: {
            isValid() {
                return this.getTitle !== '' && this.getContent !== ''
            },
            //... rendeno locali le funzioni definite nello store
            ...mapGetters({
                'getId': 'getId',
                'getTitle': 'getTitle',
                'getContent': 'getContent'
            }),
            title : {
                get : function(){
                   // return this.$store.state.post.title;
                   return this.getTitle;
                },
                set : function(newValue){
                    //non posso usare le map qui this.setTitle = newValue;
                    this.$store.state.post.title = newValue;
                }
            },
            content : {
                get : function(){
                   return this.getContent;
                },
                set : function(newValue){
                    this.$store.state.post.content = newValue;
                }
            }

        }
    }

      $('#create-item').modal( 'hide' );
</script>

<style scoped>

</style>