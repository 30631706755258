<template>
    <form action="" @submit="createPost('create')">
        <h4 class="text-center font-weight-bold">Post creation form</h4>
        <div class="form-group">
            {{ title}}
            <input type="text" placeholder="Post title" v-model="title" class="form-control">

        </div>
        <div class="form-group">
            <textarea v-model="content" placeholder="Post content" class="form-control">
            </textarea>
        </div>
        <div class="form-group">
            <button :disabled="!isValid" class="btn btn-block btn-primary" @click.prevent="createPost('create')">Submit</button>
        </div>
    </form>
</template>

<script>

    import {mapGetters,mapMutations} from 'vuex'
    
    export default {
        name: "CreatePost",
        data() {
            return {
                post: {
                    title: '',
                    content: ''
                }
            }
        },
        mounted() {

        },
        methods: {
            createPost(stato) {
                if (stato = 'create')
                    this.$store.dispatch('createPost', this.$store.state.post)
            },
            ...mapMutations([
                'setTitle'
                ])

        },
        computed: {
            isValid() {
                return this.getTitle !== '' && this.getContent !== ''
            },
            //... rendeno locali le funzioni definite nello store
            ...mapGetters({
                'getTitle': 'getTitle',
                'getContent': 'getContent'
            }),
            title : {
                get : function(){
                   // return this.$store.state.post.title;
                   return this.getTitle;
                },
                set : function(newValue){
                    //non posso usare le map qui this.setTitle = newValue;
                    this.$store.state.post.title = newValue;
                }
            },
            content : {
                get : function(){
                   return this.getContent;
                },
                set : function(newValue){
                    this.$store.state.post.content = newValue;
                }
            }

        }
    }
</script>

<style scoped>

</style>