var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function($event) {
          return _vm.createPost("create")
        }
      }
    },
    [
      _c("h4", { staticClass: "text-center font-weight-bold" }, [
        _vm._v("Post creation form")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Post title" },
          domProps: { value: _vm.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.title = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content"
            }
          ],
          staticClass: "form-control",
          attrs: { placeholder: "Post content" },
          domProps: { value: _vm.content },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.content = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-primary",
            attrs: { disabled: !_vm.isValid },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.createPost("create")
              }
            }
          },
          [_vm._v("Submit")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }