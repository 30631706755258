                                
            <template>
            
                <v-card
                        max-width="500"
                        class="mx-auto"
                    >
                    <v-toolbar
                        color="blue"
                        light
                        >
                        <v-toolbar-title>Loggato non Attivo </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon 
                             @click="getData()"
                            >mdi-reload</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="32"></v-progress-circular>
                    </v-overlay>
                    <v-list>
                        <v-list-item
                            v-for="item in items"
                            :key="item.name"
                            @click="ListActionClick(item.id)"
                        >

                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-avatar>
                                <v-img :src="item.avatar"></v-img>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template> 


<script>

    import axios from "axios";
    export default {
        data () {
        return {
            overlay: false,
            items_: [
            { name: 'Jason Oner', avatar: 'http://localhost/img/no_avatar.jpg' },
            { name: 'Travis Howard', avatar: 'http://localhost/img/no_avatar.jpg' },
            { name: 'Ali Connors', avatar: 'http://localhost/img/no_avatar.jpg' },
            { name: 'Cindy Baker', avatar: 'http://localhost/img/no_avatar.jpg' },
            ],
            items: [],
            item: {
                name: '',
                avatar: '',
            },
        }
        },
        mounted() {
            console.log('componente montato');
            this.getData();
        },
        methods: {
            getData(){
                this.overlay = true
                return axios
                    .post("api/bloccati")
                    .then(response => {
                    console.log(response);
                    this.items = response.data
                    this.overlay = false
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },
            ListActionClick($id){
                const config = {
                    headers: {
                    "Content-Type": "application/json"
                    }
                };
                var data = JSON.stringify($id);

                this.overlay = true
                
                return axios
                    .post("api/chiudi", data, config)
                    .then(response => {
                    console.log(response);
                    this.items = response.data
                    this.overlay = false
                    })
                    .catch(e => {
                    console.log(e);
                    });
            },

        }
    }


</script>

<style scoped>

</style>

