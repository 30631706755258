import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import it from 'vuetify/src/locale/it.ts'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#607d8b',
        secondary: '#03a9f4',
        accent: '#8bc34a',
        error: '#ff5722',
        warning: '#ffc107',
        info: '#00bcd4',
        success: '#cddc39'
      },
      light: {
        primary: '#2196f3',
        secondary: '#FB8C00',
        accent: '#00bcd4',
        error: '#ff5722',
        warning: '#ff9800',
        info: '#e91e63',
        success: '#8bc34a'
      },
    },
  },
  lang: {
    locales: { it },
    current: 'it',
  },
  icons: {
    iconfont: 'mdi',
  },
});
