var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-md-8" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("Primo Componenete")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", [_vm._v("Variabile: " + _vm._s(_vm.miavariabile))]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary",
              on: {
                click: function($event) {
                  return _vm.cambiaingiallo()
                }
              }
            },
            [_vm._v("Cambia")]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h6", [_vm._v("Mia Var. Colore : " + _vm._s(_vm.colore))]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.invia } }, [_vm._v("Invia Stringa")]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _vm._v("Invio con "),
      _c("b", [_vm._v("eventBus")]),
      _vm._v(' passo parametro: "Stringa inviata" ')
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }