<template> 
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title" >Secondo Componenete</h5>
                            <br>
                            <h2>{{ nome }}</h2>
                            <input v-model="nome">
                            <button @click="nome = 'Massimo'">Cambia</button>
                            <br> 
                            <br>
                            <h6> proprietà:{{ proprieta }}</h6>
                            <h6> proprietà:{{ nome }}</h6>
                            <h6>Mia Variabile : {{ myVar }}</h6>
                            
                        <hr> 
                    </div>
                </div>
            </div>
        </div>
</template>    



<script>
    import {eventbus, eventBus} from '../app';

    export default {
        name : 'SecondoComponente',
        data() {
            return{
                miavariabile: 'rosso',
                nome : 'AAA',
                myVar : 'Bianco'
            }
        },
        created(){
            eventBus.$on('inviaStringa',(v)=>{this.myVar = v;});
        },
        mounted(){
            console.log('componente montato');
            //this.miavariabile = 'Giallo';
        },
        methods:{
            //event è implicito come parametro anche se non viene passato (per le funzioni con parametri deve essere passato)
            cambiaingiallo(){
                console.log('GIALLO');
                this.miavariabile = 'Giallo';
            },
            reset(){
                this.colore = "Verde";
                eventBus.cambioColore(this.colore);
            }
        },
        props : {
            proprieta : String
 
        }
    }
</script>

<style scoped>
    p {
        border: 1px solid black;
        font-size: 56px;
    }
</style>


