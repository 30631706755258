var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "500" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "blue", light: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Personale in Pausa Pranzo")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c(
                "v-icon",
                {
                  on: {
                    click: function($event) {
                      return _vm.getData()
                    }
                  }
                },
                [_vm._v("mdi-reload")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "32" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list",
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-list-item",
            { key: item.name },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(item.name) }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-avatar",
                [_c("v-img", { attrs: { src: item.avatar } })],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }