var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-md-8" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("Secondo Componenete")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.nome))]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nome,
                expression: "nome"
              }
            ],
            domProps: { value: _vm.nome },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.nome = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.nome = "Massimo"
                }
              }
            },
            [_vm._v("Cambia")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("h6", [_vm._v(" proprietà:" + _vm._s(_vm.proprieta))]),
          _vm._v(" "),
          _c("h6", [_vm._v(" proprietà:" + _vm._s(_vm.nome))]),
          _vm._v(" "),
          _c("h6", [_vm._v("Mia Variabile : " + _vm._s(_vm.myVar))]),
          _vm._v(" "),
          _c("hr")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }